import { createStore, applyMiddleware, compose } from "redux";
import { createWrapper } from "next-redux-wrapper";
import thunk from "redux-thunk";
import createRootReducer from "./reducers/index";

const initialState = {};
const enhancers = [];
const middleware = [thunk];

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const makeStore = (initialState) =>
  createStore(
    createRootReducer, // root reducer with router state
    initialState,
    composedEnhancers
  );

export const wrapper = createWrapper(makeStore, { debug: false });
