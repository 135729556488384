export const CHAT_LOADING = "CHAT_LOADING";
export const CHAT_HISTORY = "CHAT_HISTORY";
export const SENDING_MESSAGE = "SENDING_MESSAGE";
export const CHAT_ATTACHMENT_UPLOADING = "CHAT_ATTACHMENT_UPLOADING";

export const CHAT_LOAD_MORE_LINK = "CHAT_LOAD_MORE_LINK";
export const CHAT_HISTORY_LOAD_MORE_LOADING = "CHAT_HISTORY_LOAD_MORE_LOADING";
export const CHAT_HISTORY_LOAD_MORE_LOADED = "CHAT_HISTORY_LOAD_MORE_LOADED";
export const CHAT_HISTORY_LOAD_MORE = "CHAT_HISTORY_LOAD_MORE";

export const CHAT_SEARCH_RESULT = "CHAT_SEARCH_RESULT";
export const CHAT_SEARCH_RESULT_ARE_LOADING = "CHAT_SEARCH_RESULT_ARE_LOADING";
export const CHAT_LOAD_MORE_LINK_NEXT = "CHAT_LOAD_MORE_LINK_NEXT";
export const CHAT_HISTORY_LOAD_MORE_NEXT_SUCCESS = "CHAT_HISTORY_LOAD_MORE_NEXT_SUCCESS";

export const CHAT_STICKERS = "CHAT_STICKERS";
export const CHAT_STICKERS_LOADING = "CHAT_STICKERS_LOADING";
