import axios from "axios";
import { getRefId, setGetParam, isLoggedIn } from "./global";
import { API_URL } from "../config/config";
import { USER_SESSION, USER_SESSION_IS_LOADING, USER_SESSION_LOADED, USER_ACTIVATION_STATE, USER_CONSULT_INFO, USER_CONSULT_INFO_IS_LOADING } from "../constants/users";

export function fetchUserInfo() {
  return (dispatch, getState) => {
    const sessionLoaded = getState().users.sessionLoaded;

    if (!isLoggedIn()) {
      dispatch(userSessionIsLoading(false));
      return;
    }

    if (!sessionLoaded) {
      dispatch(userSessionIsLoading(true));

      return axios
        .get(`${API_URL}user/session`)
        .then((response) => {
          return response.data;
        })
        .then((response) => {
          dispatch(userActivationStatus(response.data.attributes["activation-state"] === "active"));
          dispatch(fetchUserSessionSuccess(response.data.attributes));
          dispatch(userSessionIsLoading(false));
          dispatch(userSessionLoaded(true));
        })
        .catch((error) => {
          console.log("error loading user info", error);
          dispatch(userSessionIsLoading(false));
          dispatch(userSessionLoaded(false));
        });
    } else {
      dispatch(userSessionIsLoading(false));
    }
  };
}

export function fetchUserSessionSuccess(user) {
  return {
    type: USER_SESSION,
    user,
  };
}

export function userSessionIsLoading(bool) {
  return {
    type: USER_SESSION_IS_LOADING,
    bool,
  };
}

export function userSessionLoaded(bool) {
  return {
    type: USER_SESSION_LOADED,
    bool,
  };
}

export function userActivationStatus(bool) {
  return {
    type: USER_ACTIVATION_STATE,
    bool,
  };
}

export function fetchConsultInfo(refId) {
  return (dispatch, getState) => {
    let config = {};
    const consult = getState().users.userConsult;
    const storedRefId = getRefId();

    if (consult.id) {
      return;
    }

    dispatch(userConsultIsLoading(true));

    if (refId || storedRefId) {
      config = { params: { refid: refId ? refId : storedRefId } };
    }

    return axios
      .get(`${API_URL}user/consultant`, config)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        const data = response.data;
        if (data.id) {
          setGetParam("refid", data.id);
        }
        dispatch(fetchUserConsultSuccess(data));
        dispatch(userConsultIsLoading(false));
      })
      .catch((error) => console.log("error loading user consult", error));
  };
}

export function fetchUserConsultSuccess(data) {
  return {
    type: USER_CONSULT_INFO,
    data,
  };
}

export function userConsultIsLoading(bool) {
  return {
    type: USER_CONSULT_INFO_IS_LOADING,
    bool,
  };
}
