import { combineReducers } from "redux";
import { CONTACTS_ORG, CONTACTS_ORG_LOADING } from "../constants/contacts";
import { HYDRATE } from "next-redux-wrapper";

export function contactsOrg(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.contacts.contactsOrg;
    case CONTACTS_ORG:
      return action.data;
    default:
      return state;
  }
}

export function contactsOrgAreLoading(state = true, action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.contacts.contactsOrgAreLoading;
    case CONTACTS_ORG_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const contactsReducers = () =>
  combineReducers({
    contactsOrg: contactsOrg,
    contactsOrgAreLoading: contactsOrgAreLoading,
  });
