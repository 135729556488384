import { combineReducers } from "redux";
import { PROGRAMS, PROGRAMS_ARE_LOADING } from "../constants/programs";
import { HYDRATE } from "next-redux-wrapper";

export function programs(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.programs.programs;
    case PROGRAMS:
      return action.data;
    default:
      return state;
  }
}

export function programsAreLoading(state = true, action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.programs.programsAreLoading;
    case PROGRAMS_ARE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const programsReducers = () =>
  combineReducers({
    programs: programs,
    programsAreLoading: programsAreLoading,
  });
