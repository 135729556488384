import { useEffect } from "react";
import { Layout, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserInfo } from "../../actions/users";
import { errorMessage,  successMessage } from "../../actions/global";
import useWindowSize from "../../hooks/size";
import { MESSAGE_DEFAULT_DURATION, MESSAGE_DESKTOP_TOP_OFFSET, MESSAGE_MOBILE_TOP_OFFSET, MESSAGE_TABLET_TOP_OFFSET } from "../../constants/global";
import { ErrorsList } from "../utils/errors-list";
import { ErrorIcon, SuccessIcon } from "../utils/svg";
import { usePathname } from "next/navigation";

import dynamic from "next/dynamic";

const Header = dynamic(() => import("./Header"), {
  ssr: false,
});

const Footer = dynamic(() => import("./Footer"), {
  ssr: false,
});

const ChatBtnsGroup = dynamic(() => import("./ChatBtnsGroup"), {
  ssr: false,
});

function MainLayout(props) {
  const { chat = true } = props;
  const dispatch = useDispatch();
  const pathname = usePathname();
  const { Content } = Layout;

  const { responseSuccess, responseErrors } = useSelector((state) => state.global);

  let size = useWindowSize();

  const isMobile = size.width < 768;
  const isTablet = size.width >= 768 && size.width < 1025;

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      message.destroy();
      dispatch(errorMessage([]));
      dispatch(successMessage([]));
    };
  }, [dispatch, pathname]);

  useEffect(() => {
    const newMessageOffset = isMobile ? MESSAGE_MOBILE_TOP_OFFSET : isTablet ? MESSAGE_TABLET_TOP_OFFSET : MESSAGE_DESKTOP_TOP_OFFSET;
    message.config({
      top: newMessageOffset,
      maxCount: 1,
    });
  }, [isMobile, isTablet]);

  useEffect(() => {
    if (responseErrors.length) {
      message.error({
        content: <ErrorsList data={responseErrors} />,
        duration: MESSAGE_DEFAULT_DURATION,
        icon: <ErrorIcon />,
        onClose: () => {
          dispatch(errorMessage([]));
        },
      });
    }
  }, [dispatch, responseErrors]);

  useEffect(() => {
    if (responseSuccess.length) {
      message.success({
        content: <ErrorsList data={responseSuccess} />,
        duration: MESSAGE_DEFAULT_DURATION,
        icon: <SuccessIcon />,
        onClose: () => {
          dispatch(successMessage([]));
        },
      });
    }
  }, [dispatch, responseSuccess]);

  return (
    <div className="app__wrapper">
      <Header />
      <Content>
        <div className="content page">{props.children}</div>
      </Content>
      <Footer />
      <ChatBtnsGroup chat={chat} />
    </div>
  );
}

export default MainLayout;
