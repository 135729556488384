import { combineReducers } from "redux";
import { USER_SESSION, USER_SESSION_LOADED, USER_SESSION_IS_LOADING, USER_CONSULT_INFO, USER_CONSULT_INFO_IS_LOADING } from "../constants/users";

export function session(state = {}, action) {
  switch (action.type) {
    case USER_SESSION:
      return action.user;
    default:
      return state;
  }
}

export function sessionIsLoading(state = true, action) {
  switch (action.type) {
    case USER_SESSION_IS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function sessionLoaded(state = false, action) {
  switch (action.type) {
    case USER_SESSION_LOADED:
      return action.bool;
    default:
      return state;
  }
}

export function userConsult(state = {}, action) {
  switch (action.type) {
    case USER_CONSULT_INFO:
      return action.data;
    default:
      return state;
  }
}

export function userConsultIsLoading(state = true, action) {
  switch (action.type) {
    case USER_CONSULT_INFO_IS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const usersReducers = () =>
  combineReducers({
    session: session,
    sessionIsLoading: sessionIsLoading,
    sessionLoaded: sessionLoaded,
    userConsult: userConsult,
    userConsultIsLoading: userConsultIsLoading,
  });
