import { combineReducers } from "redux";
import { VIDEOS } from "../constants/videos";
import { HYDRATE } from "next-redux-wrapper";

export function videos(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.videos.videos;
    case VIDEOS:
      return action.data;
    default:
      return state;
  }
}

export const videosReducers = () =>
  combineReducers({
    videos: videos,
  });
