import { combineReducers } from "redux";
import { POSTS, POSTS_LOAD_MORE, POSTS_LOAD_MORE_LOADING, POST, POSTS_RECENT, POST_LOADED } from "../constants/posts";
import { HYDRATE } from "next-redux-wrapper";

export function posts(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.posts.posts;
    case POSTS:
      return action.data;
    case POSTS_LOAD_MORE:
      return {
        data: [...state.data, ...action.data.data],
        links: action.data.links,
      };
    default:
      return state;
  }
}

export function postsMoreAreLoading(state = false, action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.posts.postsMoreAreLoading;
    case POSTS_LOAD_MORE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function postsRecent(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.posts.postsRecent;
    case POSTS_RECENT:
      return action.data;
    default:
      return state;
  }
}

export function post(state = {}, action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.posts.post;
    case POST:
      return action.data;
    default:
      return state;
  }
}

export function postIsLoaded(state = true, action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.posts.postIsLoaded;
    case POST_LOADED:
      return action.bool;
    default:
      return state;
  }
}

export const postsReducers = () =>
  combineReducers({
    posts: posts,
    postsMoreAreLoading: postsMoreAreLoading,
    post: post,
    postIsLoaded: postIsLoaded,
    postsRecent: postsRecent,
  });
