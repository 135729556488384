import { combineReducers } from "redux";
import { SETTINGS, SETTINGS_LOADING } from "../constants/settings";

export function settings(state = [], action) {
  switch (action.type) {
    case SETTINGS:
      return action.data;
    default:
      return state;
  }
}

export function settingsAreLoading(state = true, action) {
  switch (action.type) {
    case SETTINGS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const settingsReducers = () =>
  combineReducers({
    settings: settings,
    settingsAreLoading: settingsAreLoading,
  });
