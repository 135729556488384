import { combineReducers } from "redux";
import { PAGE, PAGE_LOADING } from "../constants/page";
import { HYDRATE } from "next-redux-wrapper";

export function page(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.page.page;
    case PAGE:
      return action.data;
    default:
      return state;
  }
}

export function pageAreLoading(state = true, action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.page.pageAreLoading;
    case PAGE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const pageReducers = () =>
  combineReducers({
    page: page,
    pageAreLoading: pageAreLoading,
  });
