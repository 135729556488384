export const ErrorsList = (props) => {
  const errors = props.data.map((item, key) => {
    return (
      <li key={key}>
        {item.key ? `${item.key}:` : ``} {item.errors.join(", ")}
      </li>
    );
  });

  return <ul className="errors-list">{errors}</ul>;
};
