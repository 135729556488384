import Cookie from "js-cookie";

import { RESPONSE_ERRORS, RESPONSE_SUCCESS, ACCESSIBILITY, ACCESSIBILITY_ACTIVE_SIZE, ACCESSIBILITY_ACTIVE_COLOR } from "../constants/global";
import { DOMAIN } from "../config/config";

export function isLoggedIn() {
  if (getToken()) {
    return true;
  }
  return false;
}

export function setGetParam(name, param) {
  if (!name) {
    return;
  }
  Cookie.set(name, param, { domain: DOMAIN });
}

export function getToken() {
  return Cookie.get("token") || null;
}

export function getRefId() {
  return Cookie.get("refid") || null;
}

export function removeToken() {
  Cookie.remove("token", { domain: DOMAIN });
}

export function setAccessibilitySize(value) {
  Cookie.set("accessibility_size", value);
}

export function setAccessibilityColor(value) {
  Cookie.set("accessibility_color", value);
}

export function getAccessibilitySize() {
  return Cookie.get("accessibility_size") || null;
}

export function getAccessibilityColor() {
  return Cookie.get("accessibility_color") || null;
}

export function removeAccessibilitySize() {
  Cookie.remove("accessibility_size");
}

export function removeAccessibilityColor() {
  Cookie.remove("accessibility_color");
}

export function errorMessage(errors) {
  return {
    type: RESPONSE_ERRORS,
    errors,
  };
}

export function successMessage(success) {
  return {
    type: RESPONSE_SUCCESS,
    success,
  };
}

export function fetchAccessibility(bool) {
  return {
    type: ACCESSIBILITY,
    bool,
  };
}

export function fetchAccessibilityActiveSize(number) {
  return {
    type: ACCESSIBILITY_ACTIVE_SIZE,
    number,
  };
}

export function fetchAccessibilityActiveColor(number) {
  return {
    type: ACCESSIBILITY_ACTIVE_COLOR,
    number,
  };
}
