import { combineReducers } from "redux";
import {
  CHAT_ATTACHMENT_UPLOADING,
  CHAT_HISTORY,
  CHAT_LOADING,
  SENDING_MESSAGE,
  CHAT_LOAD_MORE_LINK,
  CHAT_HISTORY_LOAD_MORE_LOADING,
  CHAT_HISTORY_LOAD_MORE,
  CHAT_HISTORY_LOAD_MORE_LOADED,
  CHAT_SEARCH_RESULT_ARE_LOADING,
  CHAT_SEARCH_RESULT,
  CHAT_LOAD_MORE_LINK_NEXT,
  CHAT_HISTORY_LOAD_MORE_NEXT_SUCCESS,
  CHAT_STICKERS,
  CHAT_STICKERS_LOADING,
} from "../constants/chat";

export function chatHistory(state = [], action) {
  switch (action.type) {
    case CHAT_HISTORY:
      return action.data;
    case CHAT_HISTORY_LOAD_MORE:
      return [...action.data, ...state];
    case CHAT_HISTORY_LOAD_MORE_NEXT_SUCCESS:
      return [...state, ...action.data];
    default:
      return state;
  }
}

export function chatHistoryAreLoading(state = true, action) {
  switch (action.type) {
    case CHAT_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function chatHistoryMoreAreLoading(state = false, action) {
  switch (action.type) {
    case CHAT_HISTORY_LOAD_MORE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function chatHistoryMoreAreLoaded(state = false, action) {
  switch (action.type) {
    case CHAT_HISTORY_LOAD_MORE_LOADED:
      return action.bool;
    default:
      return state;
  }
}

export function sendingMessage(state = {}, action) {
  switch (action.type) {
    case SENDING_MESSAGE:
      return action.data;
    default:
      return state;
  }
}

export function attachmentUploading(state = false, action) {
  switch (action.type) {
    case CHAT_ATTACHMENT_UPLOADING:
      return action.bool;
    default:
      return state;
  }
}

export function loadMoreLink(state = "", action) {
  switch (action.type) {
    case CHAT_LOAD_MORE_LINK:
      return action.data;
    default:
      return state;
  }
}

export function loadMoreLinkNext(state = "", action) {
  switch (action.type) {
    case CHAT_LOAD_MORE_LINK_NEXT:
      return action.data;
    default:
      return state;
  }
}

export function chatSearchResult(state = [], action) {
  switch (action.type) {
    case CHAT_SEARCH_RESULT:
      return action.data;
    default:
      return state;
  }
}

export function chatSearchResultAreLoading(state = true, action) {
  switch (action.type) {
    case CHAT_SEARCH_RESULT_ARE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function chatStickers(state = {}, action) {
  switch (action.type) {
    case CHAT_STICKERS:
      return action.data;
    default:
      return state;
  }
}

export function chatStickersAreLoading(state = true, action) {
  switch (action.type) {
    case CHAT_STICKERS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const chatReducers = () =>
  combineReducers({
    history: chatHistory,
    historyLoading: chatHistoryAreLoading,
    chatHistoryMoreAreLoading: chatHistoryMoreAreLoading,
    chatHistoryMoreAreLoaded: chatHistoryMoreAreLoaded,
    sending: sendingMessage,
    uploading: attachmentUploading,
    loadMoreLink: loadMoreLink,
    loadMoreLinkNext: loadMoreLinkNext,
    chatSearchResult: chatSearchResult,
    chatSearchResultAreLoading: chatSearchResultAreLoading,
    chatStickers: chatStickers,
    chatStickersAreLoading: chatStickersAreLoading,
  });
