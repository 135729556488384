import React, { useState, useEffect } from "react";
import "../actions/axios";
import { wrapper } from "../store";
import "../styles/App.scss";
import { Provider } from "react-redux";
import Router from "next/router";
import { Loading } from "../components/utils/loading";
import MainLayout from "../components/layout";
import { ConfigProvider } from "antd";
import { IBM_Plex_Sans } from "next/font/google";

import Head from "next/head";

const IBMPlexSans = IBM_Plex_Sans({
  weight: ["400", "500", "700"],
  style: ["normal", "italic"],
  subsets: ["latin", "cyrillic"],
});

export default function MyApp({ Component, ...rest }) {
  const { store, props } = wrapper.useWrappedStore(rest);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    Router.events.on("routeChangeStart", () => {
      setIsLoading(true);
    });

    Router.events.on("routeChangeComplete", () => {
      setIsLoading(false);
    });

    Router.events.on("routeChangeError", () => {
      setIsLoading(false);
    });
  }, []);

  return (
    <Provider store={store}>
      <ConfigProvider theme={{ hashed: false }}>
        <style jsx global>{`
          body,
          input,
          select,
          textarea,
          [class^="ant-layout"],
          [class*=" ant-layout"] {
            font-family: ${IBMPlexSans.style.fontFamily};
          }
        `}</style>

        <Head>
          <title>Высшая школа предпринимательства, город Тверь, Институт | ЧУВО "ВШП"</title>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>

        <MainLayout>{isLoading ? <Loading contentPreloader /> : <Component {...props.pageProps} />}</MainLayout>
      </ConfigProvider>
    </Provider>
  );
}
