import { combineReducers } from "redux";
import { SETTINGS_ORG, SETTINGS_ORG_LOADING } from "../constants/settings";
import { HYDRATE } from "next-redux-wrapper";

export function settingsOrg(state = [], action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.settingsOrg.settingsOrg;
    case SETTINGS_ORG:
      return action.data;
    default:
      return state;
  }
}

export function settingsOrgAreLoading(state = true, action) {
  switch (action.type) {
    case HYDRATE:
      return action.payload.settingsOrg.settingsOrgAreLoading;
    case SETTINGS_ORG_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const settingsOrgReducers = () =>
  combineReducers({
    settingsOrg: settingsOrg,
    settingsOrgAreLoading: settingsOrgAreLoading,
  });
