import youtubePicture1 from "../../public/images/youtube_preview/youtube_picture_1.jpg";
import youtubePicture2 from "../../public/images/youtube_preview/youtube_picture_2.jpg";
import youtubePicture3 from "../../public/images/youtube_preview/youtube_picture_3.jpg";

export const VIDEOS = "VIDEOS";

export const VIDEOS_INFO = [
  {
    url: "https://rutube.ru/video/904d2483a1bc50b6addd013ddf44d266/",
    preview: youtubePicture1,
    title: "Как зарегистрироваться на сайте «Высшая школа предпринимательства»?",
  },
  {
    url: "https://rutube.ru/video/6ea748e4bbfe671dd36ce15aac50dfe1/",
    preview: youtubePicture2,
    title: "Как начать обучение на сайте «Высшая школа предпринимательства»?",
  },
  {
    url: "https://rutube.ru/video/49f40d776106fe9bedd7eec70450593c/",
    preview: youtubePicture3,
    title: "Как оплатить обучение на сайте «Высшая школа предпринимательства»?",
  },
];
