import { combineReducers } from "redux";
import { settingsOrgReducers } from "./settingsOrg";
import { settingsReducers } from "./settings";
import { usersReducers } from "./users";
import { globalReducers } from "./global";
import { chatReducers } from "./chat";
import { pageReducers } from "./page";
import { programsReducers } from "./programs";
import { contactsReducers } from "./contacts";
import { postsReducers } from "./posts";
import { videosReducers } from "./videos";

export default combineReducers({
  settingsOrg: settingsOrgReducers(),
  settings: settingsReducers(),
  users: usersReducers(),
  global: globalReducers(),
  chat: chatReducers(),
  page: pageReducers(),
  programs: programsReducers(),
  contacts: contactsReducers(),
  posts: postsReducers(),
  videos: videosReducers(),
});
